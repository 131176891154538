import {
  Component,
  ElementRef,
  OnInit,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { OffersAdminService } from '../../services/offers-admin.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CardHolderEnum,
  OfferTypeEnum,
  QuotaTypeEnum,
} from 'src/app/shared/enums/status';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { CompletedPopupComponent } from 'src/app/core/components/completed-popup/completed-popup.component';
import { ICreateForm } from 'src/app/shared/model/form.model';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { IOfferDetail } from 'src/app/shared/model/offer.model';
import { Editor } from 'primeng/editor'; // Import the Editor component
import { CanComponentDeactivate } from '../../services/candeactivate.guard';
import { Observable } from 'rxjs';
import { DiscardFormComponent } from 'src/app/core/components/discard-form/discard-form.component';

@Component({
  selector: 'app-admin-create-offer',
  templateUrl: './admin-create-offer.component.html',
  styleUrls: ['./admin-create-offer.component.scss'],
})
export class AdminCreateOfferComponent
  implements OnInit, CanComponentDeactivate
{
  @ViewChild('offerImageRef') offerImageRef!: ElementRef;
  @ViewChild('merchantLogoRef') merchantLogoRef!: ElementRef;
  @ViewChild('fileRef') fileRef!: ElementRef;
  @ViewChild('offerDetailEditor') offerDetailEditor!: Editor;
  @ViewChild('offerDetailEditorEN') offerDetailEditorEN!: Editor;
  @ViewChild('termsEditor') termsEditor!: Editor;
  @ViewChild('termsEditorEN') termsEditorEN!: Editor;

  createForm!: FormGroup;
  offerDetail!: IOfferDetail;
  selectedOfferImage!: File | null;
  previewOfferImage: any;
  selectedOfferImageName!: string;
  selectedOfferImageSize!: number | null;
  selectedMerchantImage!: File | null;
  previewMerchantImage: any;
  selectedMerchantImageName!: string;
  selectedMerchantImageSize!: number | null;
  selectedFile!: File | null;
  selectedFileName!: string;
  selectedFileSize!: number | null;
  isLoading = false;
  errMsg = '';
  isEdit!: boolean;
  id!: string;
  previousName!: string;
  previousOfferImage: any;
  previousMerchantImage: any;
  panelOpenState = false;
  isSuccess = false;

  constructor(
    private offerService: OffersAdminService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    public matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private el: ElementRef
  ) {}

  editorToolbarConfig: any; // Initialize as undefined
  offerType = [
    { view: 'Welcome Offer', viewValue: OfferTypeEnum.WELCOME },
    { view: 'Partner Offer', viewValue: OfferTypeEnum.PARTNER },
    { view: 'VISA Offer', viewValue: OfferTypeEnum.VISA },
  ];
  categories = [
    { view: 'Dining', viewValue: 'dining' },
    { view: 'Shopping', viewValue: 'shopping' },
    { view: 'Health&Spa', viewValue: 'health&spa' },
    { view: 'Airport Luggage', viewValue: 'airport luggage' },
    { view: 'Transportation', viewValue: 'transportation' },
    { view: 'Logistic', viewValue: 'logistic' },
    { view: 'Education', viewValue: 'education' },
    { view: 'Accommodation', viewValue: 'accommodation' },
    { view: 'Activity', viewValue: 'activity' },
    { view: 'Meeting Room', viewValue: 'meeting room' },
    { view: 'Airline', viewValue: 'airline' },
    { view: 'OTA', viewValue: 'ota' },
    { view: 'Business Essentials', viewValue: 'business essentials' },
    { view: 'Wellness', viewValue: 'wellness' },
  ];
  quotaType = [
    { view: 'Upload File', viewValue: QuotaTypeEnum.FORM },
    { view: 'Auto Generate', viewValue: QuotaTypeEnum.AUTO },
  ];

  cardHolder = [
    {
      view: 'For International & Thai Cardholder',
      viewValue: CardHolderEnum.THAI,
    },
    {
      view: 'For International Cardholder',
      viewValue: CardHolderEnum.INTER,
    },
  ];

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'] as string;
    const edit = this.route.snapshot.fragment;
    this.isEdit = edit === 'edit' ? true : false;

    if (this.isEdit && this.id) {
      this.initEditForm();
      this.getOfferDetail();
    } else {
      this.initForm();

      this.addValidators();
    }

    this.initToolbar();
  }

  initToolbar() {
    this.editorToolbarConfig = [
      { label: 'Bold', icon: 'pi pi-bold' },
      { label: 'Italic', icon: 'pi pi-italic' },
      { label: 'Underline', icon: 'pi pi-underline' },
      { label: 'Bullet List', icon: 'pi pi-list' },
      { label: 'Link', icon: 'pi pi-link' },
    ];
  }

  onBrowseClick(inputName: string) {
    if (inputName === 'offerImage') {
      const inputElement: HTMLInputElement = this.offerImageRef.nativeElement;
      inputElement.click();
    } else if (inputName === 'merchantImage') {
      const inputElement: HTMLInputElement = this.merchantLogoRef.nativeElement;
      inputElement.click();
    } else {
      const inputElement: HTMLInputElement = this.fileRef.nativeElement;
      inputElement.click();
    }
  }

  initForm() {
    this.createForm = this.fb.group({
      offerType: [null, Validators.required],
      offerName: [null, Validators.required],
      offerNameEN: [null, Validators.required],
      offerImage: [null, Validators.required],
      merchantName: [null, Validators.required],
      merchantNameEN: [null, Validators.required],
      merchantImage: [null, Validators.required],
      // shortDescription: [null, Validators.required],
      // shortDescriptionEN: [null, Validators.required],
      offerStart: [null, Validators.required],
      offerEnd: [null, Validators.required],
      category: [null, Validators.required],
      cardHolder: [null],
      offerDetail: [null, Validators.required],
      offerDetailEN: [null, Validators.required],
      terms: [null, Validators.required],
      termsEN: [null, Validators.required],
      quotaType: [null],
      offerLimit: [null],
      formUpload: [null],
    });
  }

  initEditForm() {
    this.createForm = this.fb.group({
      offerName: [null, Validators.required],
      offerNameEN: [null, Validators.required],
      offerImage: [null],
      merchantName: [null, Validators.required],
      merchantNameEN: [null, Validators.required],
      merchantImage: [null],
      // shortDescription: [null, Validators.required],
      // shortDescriptionEN: [null, Validators.required],
      offerStart: [null, Validators.required],
      offerEnd: [null, Validators.required],
      category: [null, Validators.required],
      cardHolder: [null],
      offerDetail: [null, Validators.required],
      offerDetailEN: [null, Validators.required],
      terms: [null, Validators.required],
      termsEN: [null, Validators.required],
    });
  }

  addValidators() {
    this.createForm.get('offerType')?.valueChanges.subscribe((value) => {
      if (value === OfferTypeEnum.WELCOME) {
        this.createForm.controls['quotaType'].setValidators([
          Validators.required,
        ]);
        this.createForm.controls['offerLimit'].setValidators([
          Validators.required,
        ]);
        // this.createForm.controls['category'].clearValidators();

        this.createForm.controls['cardHolder'].clearValidators();
      } else if (value === OfferTypeEnum.VISA) {
        this.createForm.controls['cardHolder'].setValidators([
          Validators.required,
        ]);
        // this.createForm.controls['category'].setValidators([
        //   Validators.required,
        // ]);
        this.createForm.controls['quotaType'].clearValidators();
        this.createForm.controls['offerLimit'].clearValidators();
      } else {
        // this.createForm.controls['category'].setValidators([
        //   Validators.required,
        // ]);
        this.createForm.controls['quotaType'].clearValidators();
        this.createForm.controls['cardHolder'].clearValidators();
        this.createForm.controls['offerLimit'].clearValidators();
      }

      this.createForm.controls['quotaType'].updateValueAndValidity();
      this.createForm.controls['cardHolder'].updateValueAndValidity();
      this.createForm.controls['offerLimit'].updateValueAndValidity();
      // this.createForm.controls['category'].updateValueAndValidity();
    });

    this.createForm.get('quotaType')?.valueChanges.subscribe((value) => {
      if (value === QuotaTypeEnum.FORM) {
        this.createForm.controls['formUpload'].setValidators([
          Validators.required,
        ]);
      } else {
        this.createForm.controls['formUpload'].clearValidators();
      }

      this.createForm.controls['formUpload'].updateValueAndValidity();
    });
  }

  getOfferDetail() {
    this.isLoading = true;
    this.offerService.getOfferDetail(this.id).subscribe({
      next: (data) => {
        console.log(data);
        this.offerDetail = data.data;
        this.patchValue();
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
        if (!err.result) {
          this.router.navigate(['/admin/offers']);
        }
      },
    });
  }

  patchValue() {
    const offerStart = this.offerDetail.offerStart.split('/');
    const offerEnd = this.offerDetail.offerEnd.split('/');

    const dateStart = new Date(
      Number(offerStart[2]),
      Number(offerStart[1]) - 1,
      Number(offerStart[0])
    );
    const dateEnd = new Date(
      Number(offerEnd[2]),
      Number(offerEnd[1]) - 1,
      Number(offerEnd[0])
    );

    const patchValue: any = {
      offerName: this.offerDetail.offerName,
      offerNameEN: this.offerDetail.offerNameEN,
      offerImage: null,
      merchantName: this.offerDetail.merchantName,
      merchantNameEN: this.offerDetail.merchantNameEN,
      merchantImage: null,
      shortDescription: this.offerDetail.shortDescription,
      shortDescriptionEN: this.offerDetail.shortDescriptionEN,
      offerStart: dateStart,
      offerEnd: dateEnd,
      offerDetail: this.offerDetail.offerDetail,
      offerDetailEN: this.offerDetail.offerDetailEN,
      terms: this.offerDetail.terms,
      termsEN: this.offerDetail.termsEN,
      category: this.offerDetail.category ? this.offerDetail.category : null,
    };
    if (this.offerDetail.offerType === OfferTypeEnum.VISA) {
      patchValue.cardHolder = this.offerDetail.cardHolder;
      this.createForm.get('cardHolder')?.setValidators([Validators.required]);
      this.createForm.get('cardHolder')?.updateValueAndValidity();
    }

    this.createForm.patchValue(patchValue);
    this.previousName = this.offerDetail.offerName;
    this.previousOfferImage = this.offerDetail.offerImage;
    this.previousMerchantImage = this.offerDetail.merchantImage;
    // Get references to the editor instances
    if (
      this.offerDetailEditor &&
      this.termsEditor &&
      this.offerDetailEditorEN &&
      this.termsEditorEN
    ) {
      this.offerDetailEditor.quill.root.innerHTML =
        this.offerDetail.offerDetail;
      this.offerDetailEditorEN.quill.root.innerHTML =
        this.offerDetail.offerDetailEN;
      this.termsEditor.quill.root.innerHTML = this.offerDetail.terms;
      this.termsEditorEN.quill.root.innerHTML = this.offerDetail.termsEN;
    } else {
      // Handle the case where editors are not yet initialized
      console.error('Editors not yet initialized');
    }
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (files && files.length > 0) {
      const file = files[0];
      console.log(file);
      if (file.type.includes('csv')) {
        this.selectedFile = file;
        this.selectedFileName = this.selectedFile.name;
        this.selectedFileSize = this.selectedFile.size;
        this.createForm.patchValue({ formUpload: this.selectedFile });
      } else {
        alert('Please upload a valid file (only csv).');
      }
    }
  }

  onImageSelected(event: Event, inputName: string) {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (files && files.length > 0) {
      const file = files[0];
      console.log(file);

      if (file.type.startsWith('image/') && !file.type.includes('gif')) {
        // Valid image type
        if (inputName === 'offerImage') {
          this.selectedOfferImage = file;
          this.selectedOfferImageName = this.selectedOfferImage.name;
          this.selectedOfferImageSize = this.selectedOfferImage.size;
          this.createForm.patchValue({ offerImage: this.selectedOfferImage }); // Update form control

          // Create a preview URL using DomSanitizer
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.previewOfferImage = this.sanitizer.bypassSecurityTrustUrl(
              e.target.result
            );
          };
          reader.readAsDataURL(file);
        } else if (inputName === 'merchantImage') {
          this.selectedMerchantImage = file;
          this.selectedMerchantImageName = this.selectedMerchantImage.name;
          this.selectedMerchantImageSize = this.selectedMerchantImage.size;

          this.createForm.patchValue({
            merchantImage: this.selectedMerchantImage,
          }); // Update form control

          // Create a preview URL using DomSanitizer
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.previewMerchantImage = this.sanitizer.bypassSecurityTrustUrl(
              e.target.result
            );
          };
          reader.readAsDataURL(file);
        }
      } else {
        alert(
          'Please upload a valid image file (JPEG or PNG). GIF files are not allowed.'
        );
      }
    }
  }

  onDeleteFile(inputName: string) {
    if (inputName === 'formUpload') {
      this.selectedFile = null;
      this.selectedFileName = '';
      this.selectedFileSize = null;
      this.createForm.patchValue({ formUpload: null });
      this.createForm.get('formUpload')?.markAsTouched();
      const inputElement: HTMLInputElement = this.fileRef.nativeElement;
      inputElement.value = '';
    } else if (inputName === 'offerImage') {
      this.selectedOfferImage = null;
      this.selectedOfferImageName = '';
      this.previewOfferImage = null;
      this.selectedOfferImageSize = null;
      this.createForm.patchValue({ offerImage: null });
      this.createForm.get('offerImage')?.markAsTouched();
      const inputElement: HTMLInputElement = this.offerImageRef.nativeElement;
      inputElement.value = '';
    } else {
      this.selectedMerchantImage = null;
      this.selectedMerchantImageName = '';
      this.previewMerchantImage = null;
      this.selectedMerchantImageSize = null;
      this.createForm.patchValue({ merchantImage: null });
      this.createForm.get('merchantImage')?.markAsTouched();
      const inputElement: HTMLInputElement = this.merchantLogoRef.nativeElement;
      inputElement.value = '';
    }
  }

  onlyNumberAccept(event: any) {
    const inputValue = event.target.value;

    // Remove non-numeric characters from the input
    const numericValue = inputValue.replace(/\D/g, '');

    // Update the card input field with the formatted value
    this.createForm.get('offerLimit')?.setValue(numericValue);
  }

  onOpenCompleted() {
    const dialogRefComplete = this.matDialog.open(CompletedPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: {
        location: window.location.pathname,
      },
      disableClose: true,
    });
  }

  formSumary(): FormData {
    const dateStart = this.convertDate(this.createForm.value.offerStart);
    const dateEnd = this.convertDate(this.createForm.value.offerEnd);
    const formSummary: ICreateForm = {
      offerType: this.createForm.value.offerType,
      offerName: this.createForm.value.offerName,
      offerNameEN: this.createForm.value.offerNameEN,
      offerImage: this.createForm.value.offerImage,
      category: this.createForm.value.category,
      merchantName: this.createForm.value.merchantName,
      merchantNameEN: this.createForm.value.merchantNameEN,
      merchantImage: this.createForm.value.merchantImage,
      shortDescription: '',
      shortDescriptionEN: '',
      offerStart: dateStart,
      offerEnd: dateEnd,
      offerDetail: this.createForm.value.offerDetail,
      offerDetailEN: this.createForm.value.offerDetailEN,
      terms: this.createForm.value.terms,
      termsEN: this.createForm.value.termsEN,
    };

    if (formSummary.offerType === OfferTypeEnum.WELCOME) {
      formSummary.quotaType = this.createForm.value.quotaType;
      formSummary.offerLimit = this.createForm.value.offerLimit;
      if (formSummary.quotaType === QuotaTypeEnum.FORM) {
        formSummary.formUpload = this.createForm.value.formUpload;
      }
    } else if (formSummary.offerType === OfferTypeEnum.VISA) {
      formSummary.cardHolder = this.createForm.value.cardHolder;
      // formSummary.category = this.createForm.value.category;
    }
    // else {
    //   formSummary.category = this.createForm.value.category;
    // }

    const formData: FormData = new FormData();
    for (const [key, value] of Object.entries(formSummary)) {
      formData.append(key, value);
    }

    return formData;
  }

  formEditSummary(): FormData {
    const dateStart = this.convertDate(this.createForm.value.offerStart);
    const dateEnd = this.convertDate(this.createForm.value.offerEnd);
    const formSummary: ICreateForm = {
      offerType: this.createForm.value.offerType, //it should be undefiend
      offerName:
        this.previousName !== this.createForm.value.offerName
          ? this.createForm.value.offerName
          : null,
      offerNameEN:
        this.previousName !== this.createForm.value.offerNameEN
          ? this.createForm.value.offerNameEN
          : null,
      offerImage: this.createForm.value.offerImage
        ? this.createForm.value.offerImage
        : null,
      merchantName: this.createForm.value.merchantName,
      merchantNameEN: this.createForm.value.merchantNameEN,
      merchantImage: this.createForm.value.merchantImage
        ? this.createForm.value.merchantImage
        : null,
      shortDescription: this.createForm.value.shortDescription,
      shortDescriptionEN: this.createForm.value.shortDescriptionEN,
      offerStart: dateStart,
      offerEnd: dateEnd,
      offerDetail: this.createForm.value.offerDetail,
      offerDetailEN: this.createForm.value.offerDetailEN,
      terms: this.createForm.value.terms,
      termsEN: this.createForm.value.termsEN,
      category: this.createForm.value.category
        ? this.createForm.value.category
        : null,
    };

    if (this.offerDetail.offerType === OfferTypeEnum.VISA) {
      formSummary.cardHolder = this.createForm.value.cardHolder;
    }

    const formData: FormData = new FormData();
    for (const [key, value] of Object.entries(formSummary)) {
      if (value) {
        formData.append(key, value);
      }
    }

    return formData;
  }

  onSubmit() {
    this.errMsg = '';

    if (this.createForm.invalid) {
      console.log(this.createForm.value);
      this.errMsg = 'Please complete filling this form!';
      this.createForm.markAllAsTouched();

      const firstInvalidControl: HTMLElement =
        this.el.nativeElement.querySelector('.ng-invalid[formControlName]');
      firstInvalidControl.focus();
    } else if (!this.isEdit) {
      this.isLoading = true;
      const formData = this.formSumary();
      this.offerService.createOffer(formData).subscribe({
        next: (data) => {
          console.log(data);
          this.isLoading = false;
        },
        error: (err) => {
          console.log(err);
          this.errMsg = err.statusMessage;
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.isSuccess = true;
          this.onOpenCompleted();
        },
      });
    } else if (this.isEdit) {
      this.isLoading = true;
      const formData = this.formEditSummary();
      this.offerService.editOfferInformation(formData, this.id).subscribe({
        next: (data) => {
          console.log(data);
          this.isLoading = false;
        },
        error: (err) => {
          console.log(err);
          this.errMsg = err.statusMessage;
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.isSuccess = true;
          this.onOpenCompleted();
        },
      });
    }
  }

  convertDate(date: any) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    const result = `${year}-${month}-${day}`;
    return result;
  }

  openDiscardForm() {
    const dialogRef = this.matDialog.open(DiscardFormComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      disableClose: true,
    });

    return dialogRef.afterClosed();
  }

  canDeactivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.createForm.touched && !this.isSuccess) {
      return this.openDiscardForm();
    } else {
      return true;
    }
  }
}
